import React, { useEffect, useState } from 'react';
import './RsvpForm.css';
import { Input } from './Input';
import { Button } from './Button';
import { enrollmentService } from '../_services/enrollment.service'
import { history } from '../_helpers';

import { slotsService } from '../_services/slot.service';
import { descService } from '../_services/desc.service';
import { Loading } from './Icons';
import YoutubeEmbed from './media/YoutubeEmbed';
import PostText from './shared/DetailScreen';

import {
    useParams
} from "react-router-dom";

import { manthras } from '../config';

const initialValues = {
    name: '',
    surname: '',
    phone: '',
    slot: '',
    age: '',
    location: '',
    occupation: '',
    language: '',
    health: '',
    reference: '',
    email: '',
    manthra: '',
    comment: ''
};
const languageOptions = ['Hindi', 'Kannada', 'Tamil', 'Malayalam', 'Telugu', 'Marathi', 'Gujarati', 'English'];
const ignoreValidation = ['email', 'occupation', 'health', 'reference', 'comment', 'manthra', 'language'];
export const RsvpForm = () => {
    const { manthra } = useParams();
    // const location = userLocation();
    initialValues['manthra'] = manthra;
    const [values, setValues] = useState(initialValues);
    const [slots, setSlots] = useState([])
    const [desc, setDesc] = useState({})
    const [error, setError] = useState(null);
    const [selectedSlot, setSelectedSlot] = useState('');
    const [selectedLanguages, setSelectedLanguages] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    
    const locationState = history.location.state;
    const experience = locationState ? locationState.experience : null;
 
    useEffect(() => {
        if (!Object.keys(manthras).includes(manthra)) {
            history.push('/');
        }
        loadSlots();
    }, []);

    const loadSlots = async () => {
        const result = await descService.getAll();
        if (result.desc) {
            const currentDesc = result.desc.find((d) => d.manthra === manthra) || {};
            const title = manthras[manthra] ? manthras[manthra].title : '';
            currentDesc.title = title;
            setDesc(currentDesc);
        }
        console.log(desc);
        const data = await slotsService.getAll();
        let filteredSlots = data.slots || [];
        filteredSlots = filteredSlots.filter(slot => slot.belongsTo === manthra)
        setSlots(filteredSlots)
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'slot') {
            setSelectedSlot(value);
        }

        if (name === 'language') {
            const newSelectedLanuages = [...selectedLanguages]
            const index = newSelectedLanuages.indexOf(value)
            if (index >= 0) {
                newSelectedLanuages.splice(index, 1);
            } else {
                newSelectedLanuages.push(value)
            }

            setSelectedLanguages(newSelectedLanuages);
            return;
        }
        setValues({
          ...values,
          [name]: value,
        });
    };

    const validateInput = () => {
        let err = null;
        Object.keys(values).map((key) => {
            if (!ignoreValidation.includes(key) && !values[key]) {
                err = `Field ${key} cannot be empty`;
            }

            if (selectedLanguages.length < 1) {
                err = `You must select at least one language.`;
            }

            setError(err);
        });
        return err;
    }

    const updateEnrollmentExperience = async (id) => {
        try {
            if (experience && (experience.details || experience.date)) {
                enrollmentService.updateExperience({
                    ...experience,
                    enrollmentId: id
                })
            }   
        } catch (error) {
            console.log(error);
        }
    }

    const handleClick = async () => {
        try {
            console.log(manthra);
            const err = validateInput();
            if (err) return;
            setIsLoading(true);
            // updated manthra value.
            const valuesWithManthra = {...values};
            valuesWithManthra['manthra'] = manthra;
            valuesWithManthra['language'] = selectedLanguages.join()
            console.log(valuesWithManthra);
            const data = await enrollmentService.enroll(valuesWithManthra);
            await updateEnrollmentExperience(data._id);
            history.push(`/user/enrollment/${data._id}`);
            console.log(data);
        } catch (error) {
            console.log(error);
            setError(error);
            history.push(`/error/${error.message}`);
        }
    }
    return (
        <React.Fragment>
            <div className='manthra-description'>
                {/* <h1 classname='desc-title'>Welcome</h1> */}
                <PostText post={desc} />
            </div>
            {
                isLoading ? (
                    <div className="loader"><Loading /></div>
                ) : (
                    <div className="rsvp-form-container">
                        <p className="title">{manthras[manthra]? manthras[manthra]['message'] : ''}</p>
                        <p className="schedule">{manthras[manthra] ? manthras[manthra]['date'] : ''}</p>
                        <div className="card">
                        <form className="rsvp-form">
                         <p className="options-label">Languages that you know? / शिक्षा का माध्यम चुनें / ಬೋಧನೆಯ ಮಾಧ್ಯಮವನ್ನು ಆರಿಸಿ *</p>
                        {
                            languageOptions.map((language, index) => {
                                return (
                                    <div key={index + language} className="form-check form-check-inline form-choice-selection">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="language"
                                            id={`inlineLangRadio${index}`}
                                            onChange={handleInputChange}
                                            value={language}
                                            checked={selectedLanguages.includes(language)} />
                                        <label className="form-check-label" htmlFor={`inlineLangRadio${index}`}>{language}</label>
                                    </div>
                                )
                            })
                        }

                        <p className="options-label">Which session would you like to join?  / सत्र के लिए पसंदीदा समय / ಅಧಿವೇಶನಕ್ಕೆ ಆದ್ಯತೆಯ ಸಮಯ *</p>
                        <div className="slot-form-group">
                            {
                                slots.map((slot) => {
                                    return (
                                        <div key={slot._id} className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="slot"
                                                id={`inlineRadio${slot._id}`}
                                                onChange={handleInputChange}
                                                value={slot.value}
                                                checked={selectedSlot === slot.value} />
                                            <label className="form-check-label" htmlFor={`inlineRadio${slot._id}`}>{slot.value}</label>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <Input
                            classNames ={['name-input']}
                            theme="pill"
                            name="name"
                            value={values.name}
                            onChange={handleInputChange}
                            placeholder="First Name / नाम / ಹೆಸರು "
                            size="medium"
                        />
                        <Input
                            classNames ={['lastname-input']}
                            theme="pill"
                            name="surname"
                            value={values.lastname}
                            onChange={handleInputChange}
                            placeholder="Surname / उपनाम / ಉಪನಾಮ *"
                            size="medium"
                        />
                        <Input
                            classNames ={['phone-input']}
                            theme="pill"
                            name="phone"
                            onChange={handleInputChange}
                            value={values.phone}
                            placeholder="Phone Number Which has WhatsApp / आपका फ़ोन नंबर जिस पर व्हाट्सएप है / ವಾಟ್ ಸ್ ಅಪ್ ಇರುವ ಮೊಬೈಲ್‌ ನಂಬರ್ *"
                            size="medium"
                        />
                        <Input
                            classNames ={['email-input']}
                            theme="pill"
                            name="email"
                            onChange={handleInputChange}
                            value={values.email}
                            placeholder="Email / ईमेल आईडी / ಇಮೇಲ್ ಐಡಿ?"
                            size="medium"
                        />
                        <Input
                            classNames ={['age-input']}
                            theme="pill"
                            name="age"
                            onChange={handleInputChange}
                            value={values.age}
                            placeholder="Age / आयु / ವಯಸ್ಸು *"
                            size="medium"
                        />
                        <Input
                            classNames ={['location-input']}
                            theme="pill"
                            name="location"
                            onChange={handleInputChange}
                            value={values.location}
                            placeholder="Location/Place / स्थान / ವಾಸಿಸುವ ಸ್ಥಳ *"
                            size="medium"
                        />
                        <Input
                            classNames ={['occupation-input']}
                            theme="pill"
                            name="occupation"
                            onChange={handleInputChange}
                            value={values.occupation}
                            placeholder="Occupation / व्यवसाय / ವೃತ್ತಿ"
                            size="medium"
                        />
                        <Input
                            classNames ={['health-input']}
                            theme="pill"
                            name="health"
                            onChange={handleInputChange}
                            value={values.health}
                            placeholder="Any Health Problems / कोई भी स्वास्थ्य समस्या / ಯಾವುದೇ ಆರೋಗ್ಯ ಸಮಸ್ಯೆಗಳು ?"
                            size="medium"
                        />
                        <Input
                            classNames ={['reference-input']}
                            theme="pill"
                            name="reference"
                            onChange={handleInputChange}
                            value={values.reference}
                            placeholder="How did you hear of these sessions / आपको इसके बारे में कैसे पता चला / ಈ ಶಿಬಿರದ"
                            size="medium"
                        />
                        </form>
                        {error && <p className="form-error">{error}</p>}
                        </div>
                        <Button
                            onClick={handleClick}
                            classNames={['btn']}
                            primary={true} label='register'
                        />
                    </div>
                )
            }
        </React.Fragment>
    )
}
