import React from 'react';
export const Table = ({data = [], headers = []}) => {
    return (
        <div className="table-responsive">
        <table className="table table-dark table-striped">
          <thead>
            <tr>
                {headers.map((header) => <th scope="col">{header}</th>)}
            </tr>
          </thead>
          <tbody>
              {data.map((row) => {
                  return (
                    <tr>
                        {
                            Object.keys(row).map((key) => {
                                console.log(key)
                                if (['_id', '__v'].includes(key)) return null;
                                return (<td> {row[key]}</td>)
                            })
                        }
                    </tr>)
              })}
          </tbody>
        </table>
      </div>
    )
}