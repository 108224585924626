import { authHeader } from '../_helpers/auth-header';
import axios from 'axios';
import { API_URL } from '../config';

export const userService = {
    login,
    logout,
    register,
    getAll,
    getById,
    update,
    delete: _delete
};

const config = {
    apiUrl: API_URL
}

async function login(username, password) {
    const response = await  axios.post(`${config.apiUrl}/auth/login`, { email: username, password });
    const user = response.data;
    console.log(response);
    localStorage.setItem('user', JSON.stringify(user));

}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    window.location.replace('/');
}

function getAll() {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return axios.post(`${config.apiUrl}/users/register`, requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return axios.put(`${config.apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.delete(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    const data = response.data && {};
    if (response.status !== 200) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
            window.location.reload(true);
        }

        const error = response.error;
        ;
        return Promise.reject(error);
    }
    return data;
}
