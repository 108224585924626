// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.enrollment-list {
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.custom-class-done {
    background-color: #008000;
}

.save-selection-btn {
    margin-top: 20px;
    margin-bottom: 0;
}

.delete-selection-btn {
    background-color: red;
}

.footer-line {
    margin-top: 90px;
}`, "",{"version":3,"sources":["webpack://./src/components/Enrollments.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".enrollment-list {\r\n    padding-top: 80px;\r\n    padding-left: 20px;\r\n    padding-right: 20px;\r\n    padding-bottom: 20px;\r\n}\r\n\r\n.custom-class-done {\r\n    background-color: #008000;\r\n}\r\n\r\n.save-selection-btn {\r\n    margin-top: 20px;\r\n    margin-bottom: 0;\r\n}\r\n\r\n.delete-selection-btn {\r\n    background-color: red;\r\n}\r\n\r\n.footer-line {\r\n    margin-top: 90px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
