import React from 'react';

export const Modal = (props) => {
    return (
        <div className={`modal prerequisite-check-modal ${props.show ? 'show': ''}`} tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{props.title}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {props.body}
              </div>
              <div className="modal-footer">
                <button type="button" onClick={props.onClose} className="btn btn-secondary" data-bs-dismiss="modal">No</button>
                <button type="button" onClick={props.onSubmit} className="btn btn-primary">Yes</button>
              </div>
            </div>
          </div>
        </div>
      );
}