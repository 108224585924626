// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-contact-us {
    text-align: center;
  }

 .support {
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;
    color: grey;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ContactUs.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;CAED;IACG,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;EACjB","sourcesContent":[".section-contact-us {\n    text-align: center;\n  }\n\n .support {\n    margin-top: 40px;\n    margin-left: 40px;\n    margin-right: 40px;\n    color: grey;\n    text-align: center;\n    font-weight: 500;\n    font-size: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
