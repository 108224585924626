import React from 'react';
import { Button } from './Button';
import './Header.css';
import {
  Link
} from "react-router-dom";
const logo =  process.env.PUBLIC_URL + "./saama_veda_main_logo.png";

export const Header = ({ user = null, onLogin, onLogout, redirectTo }) => {
  return (
    <header>
      <div className="wrapper">
        <div onClick={() => redirectTo('/')} className="logo">
          <img src={logo} width='100' height='50'/>
        </div>
        <div>
          {user ? (
            <div className='nav-bar'>
              <Link className='nav-link' to={'/'}>Home</Link>
              <Link className='nav-link' to={'/about'}>About Guru</Link>
              <Link className='nav-link' to={'/contact'}>Contact us</Link>
              <Link className='nav-link' to={'/enrollments'}>enrollments</Link>
              <Link className='nav-link' to={'/slots'}>slots</Link>
              <Button size="small" onClick={onLogout} label="Log out" />
            </div>
          ) : (
            <div className='nav-bar'>
              <Link className='nav-link' to={'/'}>Home</Link>
              <Link className='nav-link' to={'/about'}>About Guru</Link>
              <Link className='nav-link' to={'/contact'}>Contact us</Link>
          </div>
          )}
        </div>
      </div>
    </header>
  );
}
