import React, { useEffect, useState } from 'react';
import { history } from '../_helpers';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import './Welcome.css';
import { GlobeIcon, Loading } from './Icons';
import { Modal } from './shared/Modal';
import { manthras } from '../config';
import YoutubeEmbed from './media/YoutubeEmbed';
import Slider from './Slider';
import PostText from './shared/DetailScreen';
const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'IN'
  },
  {
    code: 'kn',
    name: 'ಕನ್ನಡ',
    country_code: 'IN'
  },
  {
    code: 'hi',
    name: 'हिंदी',
    country_code: 'IN'
  },
  {
    code: 'mt',
    name: 'मराठी',
    country_code: 'IN'
  }
]

const desc = {
  title: '',
  content: `<p><br></p><p>Of the four prominent Vedas, Saama Veda is that Veda which is in "Gaana" form or in the form of music. It is the Vedas wherein the Science of  'Naada' is used in its entirety.</p><p>Lord Shree Krishna has said in the Shreemad Bhagavad Geeta that among the Vedas He is 'Saama Veda'. The basis of everything that exits in the world is 'Naada'. Nothing exists without Naada. And Saama Veda is the Veda of 'Naada'. </p><p>There were about 1000 Shaakhaas of Saama Veda in the world. Many of these Shaakhaas no longer exist. In Bhaarath ( India) , currently we can find 530 Shaakhaas. BrahmaRushi Shree K.S. Nithyaananda Swameeji has listed these Shaakhaas in his book " SaamaVeda Bhaashya Samhithaa".</p><p>To  re-establish this Science for the benefit of mankind and this world,  BrahmaRushi VedaKrushika Shree K.S. Nithyanandaji has been giving Upadesha of the manthraas in Saama Veda to many. </p><p>To educate many in saying the Saama Veda is the need of the hour. </p><p>Why is it so important now?</p><p>Saama is 'Samaadhaana' , ' Samatholana' . It brings about balance, harmony and peace. One of the main reasons for the current pandemic is this imbalance between nature and man, between one human being and another, between one organ and another organ, between one cell and cell. </p><p>By singing the Saama Gaana of the manthraas in Saama Veda, our bodies will resonate with the specific Naada and bring about good health and other benefits.</p><p>Saama Veda is a science and irrespective of one's background  it is open to all and every person can take the Upadesha, learn it, sing it and make his life fruitful and successful. </p><p>To learn it kindly register through our <b>online form(on this website)</b>. We will contact you once a group is formed. </p><p>The online Upadesha sessions are held in different languages. English, Hindi, Kannada, Telugu,  Marathi and Tamil.</p>`
}

export const Welcome = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMantra, setSelectedMantra] = useState('');
  const [values, setValues] = useState({date: '', experience: ''});
  const [lang, setLang] = useState(languages[0]);
  useEffect(() => {
  }, []);

  const { t } = useTranslation();
  const setLanguage = ({code, name, country_code}) => {
    i18next.changeLanguage(code)
    setLang(name)
  }
  const renderDropdown = () => {
    return (
      <div className="languages">
       {
            languages.map(({ code, name, country_code}) => {
              return (<button className={`btn btn-secondary  ${lang === name ? 'selected': ''}`} onClick={() => setLanguage({ code, name, country_code})} key={`${code + name + country_code}`}>
               {name}
              </button>)
            })
          }
      </div>
    )
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    console.log(values);
  };

  const renderModal = () => {
    return (
      <Modal
        onClose={() => setIsOpen(false)}
        show={isOpen}
        onSubmit={handleModalAction}
        title={'*Have you completed the Gaayathree manthra session?'}
        body={
           (<form>
             <p className="modal-title">If your answer is yes then please fill the following details and click yes button, or else click No button and enroll for Gaayathree Manthra first.</p>
          <div className="form-group">
            <label htmlFor="recipient-exp-date" className="col-form-label">What year and month did you attend the session?</label>
            <input
              type="text" className="form-control"
              name="date"
              id="recipient-exp-date" placeholder="example: 2021-01"
              onChange={handleInputChange}
              value={values.date}
            />
          </div>
          <div className="form-group">
            <label htmlFor="message-text" className="col-form-label">Share your experience with us:</label>
            <textarea
              className="form-control"
              name="experience"
              onChange={handleInputChange}
              value={values.experience}
              id="message-text">
            </textarea>
          </div>
        </form>)
        }
      />
    );
  }

  const handleModalAction = () => {
    console.log('modal action initiated')
    history.push({
      pathname: `/form/${selectedMantra}`,
      state: {
        experience: {
          details: values.experience,
          date: values.date
        }
      }
    });
  };

  const handleClick = (option) => {
    if (option !== 'gaayathree') {
      setSelectedMantra(option);
      setIsOpen(true);
    } else {
      history.push(`/form/${option}`);
    }
  }

  return (
    <React.Fragment>
      <main>
        <div className="modal-container">
          {renderModal()}
        </div>
        <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
          <div className="lang-dropdown">{renderDropdown()}</div>
          <div className="col-md-5 mx-auto my-4">
            <h1 className="display-4 fw-normal">{t("welcome_page_header")}</h1>
            <PostText post={desc} />
            <p className="lead fw-normal description-text">
              {t("registration-link-intro-line1")}
            </p>

            <p className="lead fw-normal description-text">
              {t("registration-link-intro-line2")}
            </p>
            <div className="registration-selection-links">
              <Slider items={['gaayathree']} handleClick={handleClick} />
              <p className="lead fw-normal description-text">If you have taken the Upadesha of Gayathree Manthra(Saama Veda), You may choose to take Upadesha of any of the other Manthras below.</p>       
              <div className="registration-selection-links mb-4">
                <Slider items={Object.keys(manthras).splice(1, 2)} handleClick={handleClick} />
                <hr></hr>
                <Slider items={Object.keys(manthras).splice(3, 2)} handleClick={handleClick} />
                <hr></hr>
                <Slider items={Object.keys(manthras).splice(5, 2)} handleClick={handleClick} />
                <hr></hr>
                <Slider items={Object.keys(manthras).splice(7, 2)} handleClick={handleClick} />
                <hr />
              </div>
            </div>
          </div>
        </div>
      </main>
      <article>
        <section>
          {/* <h2 className="pb-4 bold-header">{t('manthra-intro-header')}</h2> */}
          <div className="card card-image">
              <img src={process.env.PUBLIC_URL + "./saama-image-1.jfif"} width='100%' height='300'/>
              <p className="description-card-body">
                {t('welcome-page-into-image-description')}
              </p>
          </div>
          {/* <p className="description-text">
            {t('welcome-page-mathra-intro-p1')}
          </p>
          <p>
            {t('welcome-page-mathra-intro-p2')}
          </p>
          <p>
            {t('welcome-page-mathra-intro-p3')}
          </p>
          <p>
            {t('welcome-page-mathra-intro-p4')}
          </p>
          <p>
            {t('welcome-page-mathra-intro-p5')}
          </p> */}
          <>
          <YoutubeEmbed embedId="54u5yyhAJ5Q" />
          </>
          <div className="tip-wrapper">
            <span className="tip">*</span>
            {t('welcome-page-tip-text')}
          </div>
        </section>
      </article>
      <footer className="container py-5">
        <div className="row">
          <div className="col-12 col-md">
            <small className="d-block mb-3 text-muted">&copy; 2021</small>
          </div>
        </div>
      </footer>
    </React.Fragment>
  )
};
