// Routing
import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";

import './App.css';
import { RsvpForm } from './components/RsvpForm';
import { Slots } from './components/Slots';
import { Enrollments } from './components/Enrollments';
import { MyEnrollment } from './components/MyEnrollment';
import { Header } from "./components/Header";
import { Login } from './components/Login';
import { userService } from './_services/user.service';
import { ErrorPage } from "./components/ErrorPage";
import { Welcome } from "./components/Welcome";
import { About } from './components/About';
import { ContactUs } from './components/ContactUs'
function App() {
  let history = useHistory();
  const loginPath = '/login/9e35567a9becfb8be0d2397da2bcefe01c408342d25688e6f83fe49ae4293032';
  const userFormPath = '/user/enrollment/' 
  const user = JSON.parse(localStorage.getItem('user') || null);
  // Check this logic.
  // if ((!user || !user.email) && history.location.pathname !== loginPath &&
  //     !(history.location.pathname.includes(userFormPath)) &&
  //     !(history.location.pathname.includes('/error/'))
  //   ) {
  //   history.push('/form');
  // }
  const redirectTo = (path) => {
    history.push(path)
  }
  const login = () => {}

  const logout = () => {
    userService.logout();
    history.push(loginPath);
  }

  return (
    <div className="App">
      <Header user={user} redirectTo={redirectTo} onLogin={login} onLogout={logout}/>
      <Switch>
        <Route path="/" exact component={Welcome} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={ContactUs} />
        <Route path="/enrollments" exact component={Enrollments} />
        <Route path="/form/:manthra" exact component={RsvpForm} />
        <Route path="/slots" exact component={Slots} />
        <Route path={`/user/enrollment/:id`} exact component={MyEnrollment} />
        <Route path={`/error/:message`} exact component={ErrorPage} />
        <Route path={loginPath} exact component={Login} />
      </Switch>
    </div>
  );
}

export default App;
