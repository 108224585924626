import React, { useEffect, useState } from 'react';
import './Slots.css';
import { Input } from './Input';
import { Button } from './Button';
import { Loading } from './Icons';

import {
    useHistory,
} from "react-router-dom";

import { slotsService } from '../_services/slot.service';
import { manthras } from '../config';

export const Slots = () => {
    const [slots, setSlots] = useState([]);
    const [newSlot, setNewSlot] = useState('');
    const [selectedManthra, setSelectedManthra] = useState('gaayathree');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
 
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user') || null);
        if (!user) {
            history.push('/');
        }

        loadSlots()
    }, [selectedManthra]);

    const loadSlots = async () => {
        const data = await slotsService.getAll();
        let slotRecords = data.slots || [];
        slotRecords = slotRecords.filter((record) => record.belongsTo === selectedManthra)
        setSlots(slotRecords);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewSlot(value);
    };

    const validateInput = () => {
        let err = null;
        if (!newSlot) {
            err = `Field slot cannot be empty`;
        }
        setError(err);
        return err;
    }

    const deleteSlot = async (slotId) => {
        console.log(slotId)
        try {
            await slotsService.delete(slotId);
            let deletedSlots = [...slots];
            deletedSlots = deletedSlots.filter((slot) => slot._id !== slotId);
            setSlots(deletedSlots);
        } catch (err) {
            console.log(err)
            // setError(err);
            history.push(`/error/${err.message}`);
        }
    }

    const handleMantraChange = (e) => {
        const { name, value } = e.target;
        setSelectedManthra(value);
    }

    const handleClick = async () => {
        try {
            const err = validateInput();
            if (err) return;
            setIsLoading(true);
            const data = await slotsService.create({ value: newSlot, belongsTo: selectedManthra });

            // update the local slots state
            const updatedSlots = [...slots];
            updatedSlots.push(data);
            setSlots(updatedSlots);
            setIsLoading(false);
        } catch (err) {
            setError(err);
            history.push(`/error/${err.message}`);
        }
        setNewSlot('');
    }
    return (
        <React.Fragment>
            {
                isLoading ? (
                    <div className="loader"><Loading /></div>
                ) : (
                    <div className="slots-form-container">
                        <p>Choose the mantra</p>
                        {
                            Object.keys(manthras).map((manthra, index) => {
                                return (
                                    <div key={index} className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="manthra"
                                            id={`inlineLangRadio${index}`}
                                            onChange={handleMantraChange}
                                            value={manthra}
                                            checked={selectedManthra === manthra} />
                                        <label className="form-check-label" htmlFor={`inlineLangRadio${index}`}>{manthra}</label>
                                    </div>
                                )
                            })
                        }
                        <p className="title">Slots for <span className="badge bg-secondary"> {selectedManthra} </span> manthra</p>
                        <ul className="list-group slots-list">
                            {slots.map((record) => {
                                return (
                                    <li key={record._id} className="list-group-item" aria-current="true">
                                        <span className="badge bg-primary">{record.value}</span>
                                        <span onClick={() => deleteSlot(record._id)} className="float-end badge bg-danger delete-slot">
                                            delete
                                        </span>
                                    </li>
                                )
                            })}
                        </ul>

                        <Input
                            classNames ={['slot-input']}
                            theme="pill"
                            name="slot"
                            value={newSlot}
                            onChange={handleInputChange}
                            placeholder="Enter a slot"
                            size="medium"
                        />
                        {error && <p className="form-error">{error}</p>}
                        <Button
                            onClick={handleClick}
                            classNames={['btn']}
                            primary={true} label='Save slot'
                        />
                    </div>
                )
            }
        </React.Fragment>
    )
}
