import React, { useEffect, useState } from 'react';
import './Login.css';
import { Input } from './Input';
import { Button } from './Button';
import { userService } from '../_services/user.service';
import { useLocation } from 'react-router-dom';
import { history } from '../_helpers';

export const Login = () => {
    const [values, setValues] = useState({email: '', password: ''});
    const [loggingIn, setLoggingIn] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const location = useLocation();

    // reset login status
    useEffect(() => {
    }, []);


    const handleClick = async () => {
        if (values.email && values.password) {
            // get return url from location state or default to home page
            const { from } = location.state || { from: { pathname: "/" } };
            const user = await userService.login(values.email, values.password);
            history.push('/');
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(name)
        setValues({
          ...values,
          [name]: value,
        });
    };
    return (
        <React.Fragment>
            {
                loggingIn && (
                    <div>...Loading</div>
                )
            }
            {
                !loggingIn && !loggedIn && (
                    <div className="main">
                        <p className="sign">Sign in</p>
                        <form className="form1">
                            <Input
                                classNames ={['un']}
                                // className="un"
                                theme="pill"
                                name="email"
                                value={values.email}
                                onChange={handleInputChange}
                                placeholder="Email"
                                size="medium"
                            />
                            <Input
                                classNames ={['pass']}
                                // className="pass"
                                theme="pill"
                                name="password"
                                onChange={handleInputChange}
                                value={values.password}
                                placeholder="Password"
                                size="medium"
                            />
                            <Button
                                onClick={handleClick}
                                classNames={['btn']}
                                primary={true} label='Login'
                            />
                            <p className="forgot">Forgot Password?</p>  
                        </form>
                    </div>
                )
            }
        </React.Fragment>
    )
}
