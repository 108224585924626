// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container input {
    width: 80%;
    padding: 10px;
    margin: 5px;
    outline: none;
    border: 1.5px solid #ccc;
    font-family: inherit;
}

.input-container input:focus {
    border: 2px solid rgba(0, 0, 0, 0.18) !important;
    
}

.input-container .pill {
    border-radius: 5px;
}

.input-container .small {
    padding: 5px;
}

.input-container .medium {
    padding: 10px;
}

.input-container .medium {
    padding: 10px;
}

.input-container .large {
    padding: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Input.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,WAAW;IACX,aAAa;IACb,wBAAwB;IACxB,oBAAoB;AACxB;;AAEA;IACI,gDAAgD;;AAEpD;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".input-container input {\r\n    width: 80%;\r\n    padding: 10px;\r\n    margin: 5px;\r\n    outline: none;\r\n    border: 1.5px solid #ccc;\r\n    font-family: inherit;\r\n}\r\n\r\n.input-container input:focus {\r\n    border: 2px solid rgba(0, 0, 0, 0.18) !important;\r\n    \r\n}\r\n\r\n.input-container .pill {\r\n    border-radius: 5px;\r\n}\r\n\r\n.input-container .small {\r\n    padding: 5px;\r\n}\r\n\r\n.input-container .medium {\r\n    padding: 10px;\r\n}\r\n\r\n.input-container .medium {\r\n    padding: 10px;\r\n}\r\n\r\n.input-container .large {\r\n    padding: 15px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
