import React from 'react';
import './ContactUs.css';

export const ContactUs = (props) => {
    return (
        <div className=''>
            <div className={`section-contact-us`} >
                <h1>Contact us</h1>
                <div className='lead fw-normal description-text'>vedsaama@gmail.com</div>
                <a href='https://www.youtube.com/channel/UC7Wd7TOpZ0EMid3DO123Q3Q' target="_blank">Our youtube channel</a>
            </div>
            <div className='support'>*Please support us! We're looking for offerings to help us in spreading awareness of Saama Veda to every human being. Email us for more details.</div>
        </div>
      );
}

