// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.storybook-button {
    font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 700;
    border: 0;
    border-radius: 3em;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
  }
  .storybook-button--primary {
    color: white;
    background-color: #1ea7fd;
  }
  .storybook-button--secondary {
    color: #333;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
  }
  .storybook-button--small {
    font-size: 12px;
    padding: 10px 16px;
  }
  .storybook-button--medium {
    font-size: 14px;
    padding: 11px 20px;
  }
  .storybook-button--large {
    font-size: 16px;
    padding: 12px 24px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Button.css"],"names":[],"mappings":"AAAA;IACI,0EAA0E;IAC1E,gBAAgB;IAChB,SAAS;IACT,kBAAkB;IAClB,eAAe;IACf,qBAAqB;IACrB,cAAc;EAChB;EACA;IACE,YAAY;IACZ,yBAAyB;EAC3B;EACA;IACE,WAAW;IACX,6BAA6B;IAC7B,qDAAqD;EACvD;EACA;IACE,eAAe;IACf,kBAAkB;EACpB;EACA;IACE,eAAe;IACf,kBAAkB;EACpB;EACA;IACE,eAAe;IACf,kBAAkB;EACpB","sourcesContent":[".storybook-button {\r\n    font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;\r\n    font-weight: 700;\r\n    border: 0;\r\n    border-radius: 3em;\r\n    cursor: pointer;\r\n    display: inline-block;\r\n    line-height: 1;\r\n  }\r\n  .storybook-button--primary {\r\n    color: white;\r\n    background-color: #1ea7fd;\r\n  }\r\n  .storybook-button--secondary {\r\n    color: #333;\r\n    background-color: transparent;\r\n    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;\r\n  }\r\n  .storybook-button--small {\r\n    font-size: 12px;\r\n    padding: 10px 16px;\r\n  }\r\n  .storybook-button--medium {\r\n    font-size: 14px;\r\n    padding: 11px 20px;\r\n  }\r\n  .storybook-button--large {\r\n    font-size: 16px;\r\n    padding: 12px 24px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
