import React from 'react';
import { useTranslation } from "react-i18next";

import './styles/Slider.css';
const Slider = ({items, handleClick}) => {
    const { t } = useTranslation();
    return (
        <div className="container">
            {items.map((item) => {
                return (<div key={item} href="/form/gaayathree" onClick={() => handleClick(item)} className="btn btn-secondary pr-2 item"><strong>{t(`enrollment-button-${item}`)}</strong></div>)
            })}
        </div>
    );
}

export default Slider;