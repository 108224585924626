// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    display: flex;
    padding: 0 55px;
    justify-content: center;
  }
  
  .item {
    flex: 0 0 19.7%;
    text-align: center;
    margin: 0 2px;
    transition: transform 300ms ease 100ms;
  }

  .container:hover .item {
    transform: translateX(-25%);
 }

 .item:hover ~ .item {
    transform: translateX(25%);
 }

 .item:hover {
    transform: scale(1.5) !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/styles/Slider.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,uBAAuB;EACzB;;EAEA;IACE,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,sCAAsC;EACxC;;EAEA;IACE,2BAA2B;CAC9B;;CAEA;IACG,0BAA0B;CAC7B;;CAEA;IACG,gCAAgC;EAClC","sourcesContent":[".container {\n    display: flex;\n    padding: 0 55px;\n    justify-content: center;\n  }\n  \n  .item {\n    flex: 0 0 19.7%;\n    text-align: center;\n    margin: 0 2px;\n    transition: transform 300ms ease 100ms;\n  }\n\n  .container:hover .item {\n    transform: translateX(-25%);\n }\n\n .item:hover ~ .item {\n    transform: translateX(25%);\n }\n\n .item:hover {\n    transform: scale(1.5) !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
