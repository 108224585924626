import { authHeader } from '../_helpers';
import axios from 'axios';
import { API_URL } from '../config';

export const enrollmentService = {
    enroll,
    getAll,
    getById,
    update,
    bulkDelete,
    bulkUpdate,
    delete: _delete,
    updateExperience
};

const config = {
    apiUrl: API_URL
}

async function enroll(data) {
    return await  axios.post(`${config.apiUrl}/enroll`, data).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get(`${config.apiUrl}/enroll`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get(`${config.apiUrl}/enroll/${id}`).then(handleResponse);
}

function update(enrollment) {
    const requestOptions = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(enrollment)
    };

    return axios.put(`${config.apiUrl}/enroll/${enrollment.id}`, requestOptions).then(handleResponse);;
}

function bulkUpdate(enrollments) {
    const requestOptions = {
        headers: authHeader()
    };
    return axios.post(`${config.apiUrl}/enroll/bulkUpdate`, requestOptions, enrollments).then(handleResponse);;
}

function bulkDelete(enrollments) {
    const requestOptions = {
        headers: authHeader()
    };
    return axios.post(`${config.apiUrl}/enroll/bulkDelete`, requestOptions, enrollments).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.delete(`${config.apiUrl}/enroll/${id}`, requestOptions).then(handleResponse);
}

async function updateExperience(data) {
    return await  axios.post(`${config.apiUrl}/enroll/experience`, data).then(handleResponse);
}

function handleResponse(response) {
    const data = response.data || {};
    if (response.status !== 200) {
        if (response.status === 404) {
            console.log('Something went wrong', response);
        }
        console.log('Server is down');
        const error = response.error;
        ;
        return Promise.reject(error);
    }
    return data;
}
