import React from 'react';
import './About.css';
import YoutubeEmbed from './media/YoutubeEmbed';
import PostText from './shared/DetailScreen'; 
const desc = {
    title: '',
    content: `<p>BrahmaRushi VedaKrushika Shree K.S. Nithyaananda Swameeji is the Guru of thousands of students . He has been initiating and teaching all the four main Veda ( Rig Veda, Yajur Veda, Saama Veda) in his home, Veda Vijnyaana Mandira, Chikkamagalooru, Karnataka, India for many years. His students are scientists, doctors, engineers, architects, sculptors, mathematicians, astrologers, astronomers and others.</p><p>To give to the world the Science of Saama Veda he has written a book " Saama Veda Bhashya Samhita" in Kannada. The book gives the Prakruti, Vikruti, Vrundagaana , Niruktha and Bhaavaarthah of 146 Saama Veda Manthraas.</p><p>It is under his guidance and with his Blessings that the Upadesha sessions for the Saama Veda Manthras are being conducted.</p>`
  }
export const About = () => {
    return (
        <div className="about-container">
            <div className='guru-image'>
                <img src={process.env.PUBLIC_URL + "./about-guru-1.jpg"} width='350px' height='380px'/>
            </div>
            <div className='description'>
                <PostText post={desc} />
            </div>
            <div className='videos'>
                <YoutubeEmbed  embedId="M1ZJYW10V6Y" />
                <YoutubeEmbed embedId="iWCC8arNw_E" />
            </div>
            <div className='footer'></div>
        </div>
    )
}