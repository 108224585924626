// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-container {
    display: grid;
    grid-template-columns: 1fr 500px 1fr;
    grid-template-areas:
    ". image ."
    ". description ."
    ". videos ."
    "footer footer footer";
}

.about-container .left-box {
    grid-area: box;
    width: 200px;
}
.about-container .guru-image {
    grid-area: image;
    margin-top: 40px;
    align-self: center;
    justify-self: center;
}

.about-container .description {
   grid-area: description;
    margin-top: 10px;
}

.about-container .videos {
    grid-area: videos;
    margin-top: 10px;
    display: grid;
}

.footer {
    grid-area: footer;
    margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/About.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oCAAoC;IACpC;;;;0BAIsB;AAC1B;;AAEA;IACI,cAAc;IACd,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;GACG,sBAAsB;IACrB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".about-container {\n    display: grid;\n    grid-template-columns: 1fr 500px 1fr;\n    grid-template-areas:\n    \". image .\"\n    \". description .\"\n    \". videos .\"\n    \"footer footer footer\";\n}\n\n.about-container .left-box {\n    grid-area: box;\n    width: 200px;\n}\n.about-container .guru-image {\n    grid-area: image;\n    margin-top: 40px;\n    align-self: center;\n    justify-self: center;\n}\n\n.about-container .description {\n   grid-area: description;\n    margin-top: 10px;\n}\n\n.about-container .videos {\n    grid-area: videos;\n    margin-top: 10px;\n    display: grid;\n}\n\n.footer {\n    grid-area: footer;\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
