import React, { useEffect, useState } from 'react';
import './RsvpForm.css';
import { Input } from './Input';
import { Button } from './Button';
import { Table } from './Table';
import {
    useParams
  } from "react-router-dom";
import { enrollmentService } from '../_services/enrollment.service'
import './MyEnrollment.css';
import { Loading } from './Icons';
// import { useDispatch, useSelector } from 'react-redux';

export const MyEnrollment = () => {
    const [values, setValues] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        loadEnrollment()
    }, []);

    const loadEnrollment = async () => {
        const data = await enrollmentService.getById(id);
        setValues(data);
        setIsLoading(false);
    }

    return (
        <React.Fragment>
            {
                isLoading ? (
                    <div className="loader"><Loading /></div>
                ) : (
                        <div className="card my-enrollment">
                            <p className="welcome">Namaste {values.name} Ji, Your booking for {values.slot} time slot is in progress.</p>
                            <div className="details">We will contact you once the dates for the sessions are finalized.</div>
                            <p>Phone: {values.phone}</p>
                            <p>Registration ID: {values._id}</p>
                            <p>Preferred Languages: {values.language}</p>
                            <p>Save url: <a href={`https://www.saamaveda.org/user/enrollment/${values._id}`}><strong>my enrollment</strong></a> to visit this page again </p>
                        </div>
                )
            }
        </React.Fragment>
    )
}
