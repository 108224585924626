import React, { useEffect, useState } from 'react';
import './RsvpForm.css';
import {
    useParams
  } from "react-router-dom";
import './Enrollments.css';
// import { useDispatch, useSelector } from 'react-redux';

export const ErrorPage = (props) => {
    const { message } = useParams();
    return (
        <React.Fragment>
            <div className="error">
                {message}
            </div>
        </React.Fragment>
    )
}
