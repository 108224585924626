import React, { useEffect, useState } from 'react';
import './RsvpForm.css';
import { Input } from './Input';
import { Button } from './Button';
import { Table } from './Table';
import { enrollmentService } from '../_services/enrollment.service'
import {
    useHistory,
} from "react-router-dom";

import { Loading } from './Icons';

import './Enrollments.css';
import MUIDataTable from "mui-datatables";
import clsx from 'clsx'
// import { useDispatch, useSelector } from 'react-redux';
const columns = [
    {
        name: 'index',
        label: '#'
    },
    {
        name: "manthra",
        label: "Manthra"
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "surname",
      label: "Surname",
    },
    {
        name: "phone",
        label: "Phone",
    },
    {
        name: "slot",
        label: "Slot",
    },
    {
        name: "language",
        label: "Languages",
    },
    {
        name: "location",
        label: "Location",
    },
    {
        name: "occupation",
        label: "Occupation",
    },
    {
        name: "email",
        label: "Email",
    },
    {
        name: "age",
        label: "Age"
    },
    {
     name: "health",
     label: "Health",
    },
    {
        name: "reference",
        label: "Reference",
    },
    {
        name: "isComplete",
        label: "Done",
        options: {
            display: false
        },
    },
    {
        name: "_id",
        label: "Key",
        options: {
            display: false
        },
    },
    {
           name: "createdAt",
           label: "Enrollment Date"
    }
   ];
export const Enrollments = () => {
    const [values, setValues] = useState([]);
    const [rowsSelected, setselectedRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user') || null);
        if (!user) {
            history.push('/');
        } else {
            loadEnrollments()
        }
    }, []);

    const loadEnrollments = async () => {
        const data = await enrollmentService.getAll();
        const enrollments = data.enrollments || [];
        const enrollmentsWithIndex = addColumn(enrollments)
        setValues(data.enrollments || []);
    }

    const addColumn = (enrolls) => {
        const data = enrolls.map((enrollment, index) => {
            enrollment['index'] = index + 1;
            return enrollment;
        })
        return data;
    }

    const handleRowSelectionChange = (rowsSelectedData, allRows, rowsSelected) => {
        setselectedRows(rowsSelected);
    }

    const options = {
        onRowSelectionChange: handleRowSelectionChange,
        responsive: 'standard',
        setRowProps: (row, dataIndex, rowIndex) => {
            const index = columns.map(function(e) { return e.name; }).indexOf('isComplete');
            console.log(row[index], index);
          return {
            className: clsx({
              ['custom-class-done']: row[index] === true
            })
          };
        }
    };

    const handleClickDelete = async () => {
        setIsLoading(true)
        try {
            const newValues = [...values];
            const valuesTodelete = [];
            for (let index in newValues) {
                if (rowsSelected.includes(parseInt(index))) {
                    valuesTodelete.push(newValues[index])
                }
            }
            await enrollmentService.bulkDelete(valuesTodelete);
            await loadEnrollments();
            setIsLoading(false);        
        } catch (error) {
            setIsLoading(false);
            history.push(`/error/${error.message}`);
        }
    }

    const handleClick = async (status) => {
        const newValues = [...values];
        for (let index in newValues) {
            if (rowsSelected.includes(parseInt(index))) {
                newValues[index].isComplete = (status && status === 'pending') ? false: true;
            }
        }

        await enrollmentService.bulkUpdate(newValues);
        setValues(newValues || []);
        setselectedRows([]);
    }
    return (
        <React.Fragment>
            {
                isLoading ? (<div className="loader"><Loading /></div>) :
                    (
                        <div className="enrollment-list">
                        <MUIDataTable
                            title={"Enrollee list"}
                            data={values}
                            options={options}
                            columns={columns}/>
                        <Button
                            size='small'
                            onClick={() => handleClick('')}
                            classNames={['save-selection-btn']}
                            primary={true} label='mark done'
                        />
                        <Button
                            size='small'
                            onClick={() => handleClick('pending')}
                            classNames={['save-selection-btn']}
                            primary={true} label='mark pending'
                        />
                        <Button
                            size='small'
                            onClick={() => handleClickDelete()}
                            classNames={['delete-selection-btn mt-4']}
                            primary={true} label='delete'
                        />
                        <hr className='footer-line'/>
                    </div>
                    )
            }
        </React.Fragment>
    )
}
