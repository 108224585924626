import React from 'react';
import './Input.css';

/**
 * Primary UI component for user interaction
 */
 export const Input = ({
    theme = 'default',
    classNames = [],
    size = 'medium',
    onChange,
    value,
    ...props
  }) => {
    const mode = `${size} ${theme}`;
    return (
        <div className="input-container">
            <input
                type="input"
                id="input"
                className={ [mode, classNames].join(' ') }
                {...props}
                onChange={onChange}
                value={value}
            />
        </div>
    );
  };
  