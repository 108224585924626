// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slot-item {
    color: lightgray;
}

.slot-input {
    max-width: 400px;
}

.slots-list {
    margin-bottom: 20px;
}

.delete-slot {
    cursor: pointer;
}

.slots-form-container {
    background-color: #FFFFFF;
    height: 100%;
    margin: 2em auto;
    max-width: 400px;
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 1.5em;
    text-align: center;
    padding-bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/Slots.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,2CAA2C;IAC3C,oBAAoB;IACpB,kBAAkB;IAClB,oBAAoB;AACxB","sourcesContent":[".slot-item {\n    color: lightgray;\n}\n\n.slot-input {\n    max-width: 400px;\n}\n\n.slots-list {\n    margin-bottom: 20px;\n}\n\n.delete-slot {\n    cursor: pointer;\n}\n\n.slots-form-container {\n    background-color: #FFFFFF;\n    height: 100%;\n    margin: 2em auto;\n    max-width: 400px;\n    background-color: rgba(255, 255, 255, 0.08);\n    border-radius: 1.5em;\n    text-align: center;\n    padding-bottom: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
