export const API_URL = process.env.SERVICE_URL//'https://api.saamaveda.org';
// export const API_URL = 'localhost:3001';
export const manthras = {
    'gaayathree': {
        name: 'gaayathree',
        title: 'UPADESHA ABHIYAANA OF SAAMA VEDA - GAAYATHREE',
        date: '',
        message: 'Entry form for Upadesha of Saama Veda Gaayathree Manthra'
    },
    'sarasvathi': {
        name: 'Paavakaa Nah Sarasvathi',
        title: '',
        date: 'Please register now. Dates will be confirmed to you, after form submission',
        message: 'Entry form for Upadesha of Paavakaa Nah Sarasvathi Manthra'
    },
    'pibaa': {
        name: ' Pibaa Suthasya Rasinoe',
        title: '',
        date: 'Please register now. Dates will be confirmed to you, after form submission',
        message: 'Entry form for Upadesha of Pibaa Suthasya Rasinoe Manthra'
    },
    'udhghaedhabhhi': {
        name: 'Udhghaedhabhhi Shruthaamaghham',
        title: '',
        date: 'Please register now. Dates will be confirmed to you, after form submission',
        message: 'Entry form for Upadesha of Udhghaedhabhhi Shruthaamaghham Manthra'
    },
    'subrahmanya': {
        name: 'Subrahmanya',
        title: '',
        date: 'Please register now. Dates will be confirmed to you, after form submission',
        message: 'Entry form for Upadesha of Subrahmanya Manthra'
    },
    'narayaneeyam': {
        name: 'Narayaneeyam',
        title: '',
        date: 'Please register now. Dates will be confirmed to you, after form submission',
        message: 'Entry form for Upadesha of Narayaneeyam Manthra'
    },
    'shatharudreeyam': {
        name: 'SHATHARUDREEYAM',
        title: '',
        date: 'Please register now. Dates will be confirmed to you, after form submission',
        message: 'Entry form for Upadesha of SHATHARUDREEYAM Manthra'
    },
    'paanthamaa': {
        name: 'PAANTHAMAA VO ANDHASA',
        title: '',
        date: 'Please register now. Dates will be confirmed to you, after form submission',
        message: 'Entry form for Upadesha of PAANTHAMAA VO ANDHASA Manthra'
    },
    'thvaamiddhi': {
        name: 'THVAAMIDDHI HAVAAMAHAAE',
        title: '',
        date: 'Please register now. Dates will be confirmed to you, after form submission',
        message: 'Entry form for Upadesha of THVAAMIDDHI HAVAAMAHAAE Manthra'
    }
};
