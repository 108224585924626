// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* */

.main {
    background-color: #FFFFFF;
    width: 400px;
    height: 400px;
    margin: 7em auto;
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 1.5em;
    text-align: center;
    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
}

.sign {
    padding-top: 40px;
    color: grey;
    /* font-family: 'Ubuntu', sans-serif; */
    font-weight: bold;
    font-size: 23px;
}

form.form1 {
    padding-top: 20px;
}


.btn {
    cursor: pointer;
    margin-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
}

.forgot {
    text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
    color: grey;
    padding-top: 15px;
    font-weight: bold;
    cursor: pointer;
}

@media (max-width: 600px) {
    .main {
        border-radius: 0px;
    }   
}

`, "",{"version":3,"sources":["webpack://./src/components/Login.css"],"names":[],"mappings":";AACA,IAAI;;AAEJ;IACI,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,2CAA2C;IAC3C,oBAAoB;IACpB,kBAAkB;IAClB,iDAAiD;AACrD;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,uCAAuC;IACvC,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;;AAGA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kDAAkD;IAClD,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":["\r\n/* */\r\n\r\n.main {\r\n    background-color: #FFFFFF;\r\n    width: 400px;\r\n    height: 400px;\r\n    margin: 7em auto;\r\n    background-color: rgba(255, 255, 255, 0.08);\r\n    border-radius: 1.5em;\r\n    text-align: center;\r\n    box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);\r\n}\r\n\r\n.sign {\r\n    padding-top: 40px;\r\n    color: grey;\r\n    /* font-family: 'Ubuntu', sans-serif; */\r\n    font-weight: bold;\r\n    font-size: 23px;\r\n}\r\n\r\nform.form1 {\r\n    padding-top: 20px;\r\n}\r\n\r\n\r\n.btn {\r\n    cursor: pointer;\r\n    margin-top: 10px;\r\n    padding-left: 40px;\r\n    padding-right: 40px;\r\n}\r\n\r\n.forgot {\r\n    text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);\r\n    color: grey;\r\n    padding-top: 15px;\r\n    font-weight: bold;\r\n    cursor: pointer;\r\n}\r\n\r\n@media (max-width: 600px) {\r\n    .main {\r\n        border-radius: 0px;\r\n    }   \r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
