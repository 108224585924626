// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
    font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .logo {
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    border-radius: 30px;
  }
  
  h1 {
    font-weight: 900;
    font-size: 20px;
    line-height: 1;
    margin: 6px 0 6px 10px;
    display: inline-block;
    vertical-align: top;
  }
  
  button + button {
    margin-left: 10px;
  }
.nav-bar {
  display: flex;
  flex-direction: row;
}
  .nav-link {
    text-decoration: none;
    font-weight: 700;
    color: #6c757d;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA;IACI,0EAA0E;IAC1E,2CAA2C;IAC3C,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;EAChC;;EAEA;IACE,mBAAmB;IACnB,eAAe;IACf,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,sBAAsB;IACtB,qBAAqB;IACrB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;EACnB;AACF;EACE,aAAa;EACb,mBAAmB;AACrB;EACE;IACE,qBAAqB;IACrB,gBAAgB;IAChB,cAAc;EAChB","sourcesContent":[".wrapper {\r\n    font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;\r\n    border-bottom: 1px solid rgba(0, 0, 0, 0.1);\r\n    padding: 15px 20px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n  }\r\n  \r\n  .logo {\r\n    flex-direction: row;\r\n    cursor: pointer;\r\n    align-items: center;\r\n    border-radius: 30px;\r\n  }\r\n  \r\n  h1 {\r\n    font-weight: 900;\r\n    font-size: 20px;\r\n    line-height: 1;\r\n    margin: 6px 0 6px 10px;\r\n    display: inline-block;\r\n    vertical-align: top;\r\n  }\r\n  \r\n  button + button {\r\n    margin-left: 10px;\r\n  }\r\n.nav-bar {\r\n  display: flex;\r\n  flex-direction: row;\r\n}\r\n  .nav-link {\r\n    text-decoration: none;\r\n    font-weight: 700;\r\n    color: #6c757d;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
